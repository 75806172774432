import classes from "./Facts.module.css";
import React from "react";

export const Facts = () => {
  return (
    <div className={classes.facts_container}>
      <div className={classes.facts} id="facts_section">
        <h3 className={classes.facts__title}>A Bit More...</h3>
        <ul className={classes.facts__points}>
          <li>
            I’m <span>learning</span> some <span>Japanese</span>
          </li>
          <li>
            My <span>top three anime</span> are{" "}
            <span>Attack on Titan, Re:Zero, and Fate/Stay Night</span>
          </li>
          <li>
            My <span>top three shows</span> are{" "}
            <span>The Vampire Diaries, Breaking Bad, and Peaky Blinders</span>
          </li>
          <li>
            I absolutely <span>cannot say no to Tiramisu</span>
          </li>
          <li>
            and I <span>don't watch sports</span>, I really tried to
          </li>
        </ul>
      </div>
    </div>
  );
};
