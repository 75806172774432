import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import Header from "./components/layouts/Header";
import { Home } from "./components/routes/Home";
import { Footer } from "./components/layouts/Footer";
import { NotFound } from "./components/layouts/NotFound";
import "./App.css";
import { Work } from "./components/routes/Work";
import { Maintenance } from "./components/routes/Maintenance";
import { MyEco } from "./components/routes/MyEco";
import { Test } from "./components/layouts/Test";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/myEco" element={<MyEco />}></Route>
          <Route path="/test" element={<Test />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
