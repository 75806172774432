import classes from "./Header.module.css";
import logo from "../../assets/images/norbs_logo.svg";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const location = useLocation();

  return (
    <div className={classes.header_container}>
      <div className={classes.header}>
        <a className={classes.header__logo} href="/">
          <img alt="Norblit Logo" src={logo} />
        </a>
        <div
          className={`${classes.line} ${
            location.pathname === "/myeco" ? classes.header_project_line : ""
          }`}
        ></div>
        <a
          className={`${classes.header_text} ${
            location.pathname === "/myeco" ? classes.header_project_text : ""
          }`}
          href="https://norblit.com/assets/NorbeyAcevedoResume.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Resume
        </a>
        <div
          className={`${classes.line} ${
            location.pathname === "/myeco" ? classes.header_project_line : ""
          }`}
        ></div>
        <a
          className={`${classes.header_text} ${
            location.pathname === "/myeco" ? classes.header_project_text : ""
          }`}
          href="/#/work"
        >
          My Work
        </a>
      </div>
    </div>
  );
};
export default Header;
