import classes from "./Contact.module.css";
import React from "react";
import email from "../../assets/images/email_icon.svg";
import linkedin from "../../assets/images/linkedin_icon.svg";
import instagram from "../../assets/images/instagram_icon.svg";

export const Contact = (props) => {
  return (
    <div
      className={` ${
        props.center === "true"
          ? classes.contact_container_center
          : classes.contact_container
      }
      }`}
    >
      <div id="contact" className={classes.contact}>
        <h2 className={classes.contact__title}>Let's Connect</h2>
        <ul>
          <li>
            <img src={instagram} alt="Instagram Icon"></img>
            <a
              href="https://www.instagram.com/norblit"
              target="_blank"
              rel="noreferrer"
            >
              @norblit
            </a>
          </li>
          <li>
            <img src={linkedin} alt="LinkedIn Icon"></img>
            <a
              href="https://www.linkedin.com/in/norblit/"
              target="_blank"
              rel="noreferrer"
            >
              @norblit
            </a>
          </li>
          <li>
            <img src={email} alt="Letter Icon"></img>
            <a href="mailto:norbeyace99@gmail.com">norbeyace99@gmail.com</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
