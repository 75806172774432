import classes from "./CardWrapper.module.css";
export const CardWrapper = (props) => {
  return (
    <div className={classes.card_container}>
      <div className={classes.card} id={props.id}>
        <div className={classes.card_top}>
          <h2 className={classes.card_title}>{props.title}</h2>
          <h2 className={classes.card_subtitle}>{props.subtitle}</h2>
        </div>
        {props.children}
      </div>
    </div>
  );
};
