import React, { useState, useEffect } from "react";
import classes from "./Projects.module.css";
import { Project } from "./Project";
import myecoCover from "../../assets/images/MyEcoCover.webp";
import namalCover from "../../assets/images/NamalCover.webp";
import animiiCover from "../../assets/images/AniiMiiCover.webp";

export const Projects = () => {
  return (
    <div className={classes.projects_container}>
      <Project
        projectName="MyEco: Going Green with Social Presence"
        projectImage={myecoCover}
        projectLink="/#/myeco"
      />
      <Project
        projectName="Namal (UNDER MAINTENANCE)"
        projectImage={namalCover}
        projectLink="/#/work"
      />
      <Project
        projectName="AniMii (UNDER MAINTENANCE)"
        projectImage={animiiCover}
        projectLink="/#/work"
      />
    </div>
  );
};
