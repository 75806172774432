import classes from "./NotFound.module.css";
import React from "react";
import cat from "../../assets/images/404.webp";

export const NotFound = () => {
  return (
    <div className={classes.notfound}>
      <h1 className={classes.notfound_title}>404</h1>
      <img className={classes.notfound_image} src={cat} alt="Cat"></img>
      <h2 className={classes.notfound_note}>
        Sorry, this page doesn't exist, but there is this cute kiggy!
      </h2>
    </div>
  );
};
