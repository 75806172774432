import React, { useEffect, useState } from "react";
import classes from "./Spotify.module.css";

import axios from "axios";

const CLIENT_ID = "fad5d9aafedb40fa86dfb2127b6d96c6";
const CLIENT_SECRET = "1890e692a9784899a273d63409000f42";
const AUTH_URL = "https://accounts.spotify.com/api/token";

const authOptions = {
  headers: {
    Authorization: "Basic " + btoa(`${CLIENT_ID}:${CLIENT_SECRET}`),
  },
  params: {
    grant_type: "client_credentials",
  },
};
export const Spotify = () => {
  const [accessToken, setAccessToken] = useState("");
  const [songs, setSongs] = useState([]);
  const colors2 = ["#a61d11", "#3495fd", "#e3a0d4", "#ea8756", "#c9b89b"];
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        let storedToken = localStorage.getItem("spotifyAccessToken");
        let storedExpiration = localStorage.getItem("spotifyTokenExpiration");

        if (storedToken && storedExpiration) {
          const expirationTime = parseInt(storedExpiration);
          if (Date.now() < expirationTime) {
            console.log("Using cached token");
            setAccessToken(storedToken);
            return;
          }
        }

        const response = await axios.post(AUTH_URL, null, authOptions);
        const token = response.data.access_token;
        const expiresIn = response.data.expires_in * 1000; // Convert to milliseconds
        const expirationTime = Date.now() + expiresIn;
        console.log("Spotify Token has been created and is now saved!");
        setAccessToken(token);
        localStorage.setItem("spotifyAccessToken", token);
        localStorage.setItem(
          "spotifyTokenExpiration",
          expirationTime.toString()
        );
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchAccessToken();
  }, []);

  useEffect(() => {
    if (!accessToken) return;

    const cachedSongs = JSON.parse(localStorage.getItem("spotifySongs"));
    const expirationTime = parseInt(
      localStorage.getItem("spotifySongsExpiration")
    );

    if (cachedSongs && Date.now() < expirationTime) {
      console.log("Using cached songs");
      setSongs(cachedSongs);
    } else {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://api.spotify.com/v1/playlists/3IokCyjk4VRoaLzXcZOHUK/tracks?market=US&fields=items%28track%28name%2C+artists%28name%29%29%29`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          console.log("Request for songs sent!");
          // console.log(response.data);
          const tracks = response.data.items.map((item) => {
            const { name, artists } = item.track;
            return {
              name,
              artists: artists.map((artist) => artist.name).join(", "),
            };
          });

          // Save the first 5 songs
          setSongs(tracks.slice(0, 5));
          const expirationTime = Date.now() + 24 * 60 * 60 * 1000; // Set expiration to 1 day from now
          localStorage.setItem(
            "spotifySongs",
            JSON.stringify(tracks.slice(0, 5))
          );
          localStorage.setItem(
            "spotifySongsExpiration",
            expirationTime.toString()
          );
        } catch (error) {
          console.error("There was a problem with the request:", error);
        }
      };

      fetchData();
    }
  }, [accessToken]);

  return (
    <div className={classes.spotify_container}>
      <div className={classes.spotify}>
        <h1 className={classes.spotify_top_five_title}>
          Recently Listened to on Spotify
        </h1>
        <div className={classes.spotify_songs}>
          {songs.map((song, index) => (
            <div
              className={classes.spotify_song}
              style={{ border: colors2[index % colors2.length] + " solid 1px" }}
              key={index}
            >
              <div
                className={classes.spotify_song_cover}
                style={{ backgroundColor: colors2[index % colors2.length] }}
              ></div>
              <div className={classes.spotify_song_info}>
                <h3>{song.name}</h3>
                <p>{song.artists}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
