import React from "react";
import classes from "./Project.module.css";

export const Project = ({ projectName, projectImage, projectLink }) => {
  return (
    <a className={`${classes.project_container}`} href={projectLink}>
      <div className={classes.project}>
        <div className={`${classes.img_hover_zoom}`}>
          <div
            className={classes.project_thumbnail}
            style={{ backgroundImage: `url(${projectImage})` }}
            alt={projectName}
          />
        </div>
        <h3 className={classes.project_title}>{projectName}</h3>
      </div>
    </a>
  );
};
