import React, { useState, useEffect } from "react";
import { Hero } from "../UI/Hero";
import { Menu } from "../UI/Menu";
import cover from "../../assets/images/forest.webp";
import { CardWrapper } from "../UI/CardWrapper";
import { Context } from "../UI/Context";
import classes from "./MyEco.module.css";
import emission from "../../assets/images/emission.webp";
import appIcon from "../../assets/images/MyEcoAppIcon.webp";
import mockups from "../../assets/images/Mockups.webp";
import appsAnalysis from "../../assets/images/AppsAnalysis.webp";
import female from "../../assets/images/female.webp";
import flow from "../../assets/images/flow.webp";
import drawing from "../../assets/images/drawing.webp";
import wireframes from "../../assets/images/wireframes.webp";
import wireframesWhite from "../../assets/images/wireframes_white.webp";
import branding from "../../assets/images/branding.webp";
import logoBreakdown from "../../assets/images/logo_breakdown.webp";
import styles from "../../assets/images/styles.webp";
import stylesWhite from "../../assets/images/styles_white.webp";
import study from "../../assets/images/study.webp";
import ba1 from "../../assets/images/BA1.webp";
import ba1White from "../../assets/images/BA1_white.webp";
import ba2 from "../../assets/images/BA2.webp";
import ba2White from "../../assets/images/BA2_white.webp";
import ba3 from "../../assets/images/BA3.webp";
import ba3White from "../../assets/images/BA3_white.webp";
import ba4 from "../../assets/images/BA4.webp";
import ba4White from "../../assets/images/BA4_white.webp";
import { Contact } from "../layouts/Contact.js";

export const MyEco = (props) => {
  const sections = [
    "Problem",
    "Solution",
    "Competitive Analysis",
    "User Persona",
    "User Flow",
    "Wireframes",
    "Lo-Fi Prototype",
    "Branding + Identity",
    "Usability Study",
    "Mockups",
    "Hi-Fi Prototype",
    "Next Steps",
  ];
  const [activeSection, setActiveSection] = useState(null);

  // ? photoswipe function
  useEffect(() => {
    const initializeLightbox = async () => {
      // Import PhotoSwipeLightbox and any other dependencies asynchronously
      const { default: PhotoSwipeLightbox } = await import(
        "../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js"
      );
      const { default: PhotoSwipe } = await import(
        "../../../node_modules/photoswipe/dist/photoswipe.esm.js"
      );

      // Create and initialize the lightbox
      const lightbox = new PhotoSwipeLightbox({
        gallery: ".proj_image",
        children: "a",
        initialZoomLevel: 0.2,
        secondaryZoomLevel: 1,
        pswpModule: () => PhotoSwipe,
        padding: { top: 20, bottom: 40, left: 100, right: 100 },
      });
      lightbox.init();
    };

    initializeLightbox();
  }, []);

  // ? scrollspy function
  useEffect(() => {
    const handleScroll = () => {
      const wrappers = document.querySelectorAll(".CardWrapper_card__LVnD9");
      let currentSection = null;

      wrappers.forEach((wrapper) => {
        const wrapperTop = wrapper.offsetTop;
        const wrapperHeight = wrapper.clientHeight;
        if (
          window.scrollY >= wrapperTop - 200 &&
          window.scrollY < wrapperTop + wrapperHeight
        ) {
          currentSection = wrapper.id;
        }
      });

      setActiveSection(currentSection); // Set a default value if currentSection is null
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Hero
        src={cover}
        title="MyEco"
        subtitle="Carbon Footprint Tracker App"
        pos="10% 45%"
      />
      <Context
        timeline="Aug '23 - Sep '23"
        platform="Mobile & Tablet"
        role="UX Designer"
        tools="Figma & Miro"
        purpose="Google UX Design Professional Certificate"
      />
      <Menu
        activeSection={activeSection}
        amount={sections.length}
        titles={sections}
      />
      {
        //? Problem
      }
      <CardWrapper
        id="problem"
        title="Problem"
        subtitle="How can we provide users a more engaging way to go green?"
      >
        <div className={classes.emission_image} alt="Smoke Emissions"></div>
        <p className={classes.body_text3}>
          Given the <span>recent shifts in our climate</span>, there is a
          <span> widespread desire</span> among individuals{" "}
          <span>worldwide</span> to gain a deeper <span>understanding</span> of{" "}
          <span>their habits</span> and{" "}
          <span>impact on their carbon footprint</span>. The problem is that{" "}
          <span>many of these apps are unheard of</span> without strictly
          looking for them. Based on my findings,{" "}
          <span>
            if users do install and use them, they end up leaving shortly after
          </span>{" "}
          due to its <span>lack of engagement</span>. Finding the right approach
          can help <span>foster environmental awareness</span> and
          <span> empower individuals</span> to{" "}
          <span>make informed choices</span>.
        </p>
      </CardWrapper>
      {
        //? Solution
      }
      <CardWrapper
        id="solution"
        title="Solution"
        subtitle='A mobile platform with "Social Presence"'
      >
        <div className={`${classes.flex} ${classes.solution}`}>
          <img className={classes.appIcon} src={appIcon} alt="MyEco App Icon" />
          <p className={classes.body_text}>
            Introducing <span>MyEco</span>, a <span>mobile app</span> designed
            to <span>assist users in monitoring their emissions</span> and
            contributions, accessing <span>educational resources</span> on
            reducing carbon footprints, and{" "}
            <span>engaging with a community </span>for mutual learning and
            support.
          </p>
        </div>

        <div className="proj_image">
          <a
            href={mockups}
            data-pswp-width="3758.28"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.mockups}
              src={mockups}
              alt="MyEco Mockups"
            />
          </a>
        </div>
      </CardWrapper>
      {
        //? Competitive Analysis
      }
      <CardWrapper
        id="competitive%20analysis"
        title="Competitive Analysis"
        subtitle="Mobile App Comparison"
      >
        <p className={classes.body_text}>
          As to how I reached that solution, I{" "}
          <span>originally compared four mobile apps</span> that serve that same
          purpose of minimizing ones’ carbon footprint. I{" "}
          <span>captured their varying set of controls</span> such as their
          features, navigation, visual design,{" "}
          <span>and gauged their general app experience</span>.
        </p>
        <p className={classes.body_text}>
          In my findings, I noted that{" "}
          <span>
            neither app had a user-to-user community within their platforms
          </span>
          . This <span>lack of social presence</span> highlighted{" "}
          <span>
            a key weakness point that I could use as a catalyst towards my
            solution
          </span>
          .
        </p>

        <img
          className={classes.apps}
          src={appsAnalysis}
          alt="App Icon Collection"
        />
      </CardWrapper>
      {
        //? User Persona
      }
      <CardWrapper
        id="user%20persona"
        title="User Persona"
        subtitle="Emily (Yoga Instructor)"
      >
        <div className={`${classes.flex} ${classes.user_persona}`}>
          <img className={classes.female} src={female} alt="Female Person" />
          <div>
            <div>
              <h4 className={classes.subheading}>About</h4>
              <p className={classes.body_text2}>
                Emily grew up in a household that emphasized sustainable living
                and has always been conscious of her carbon footprint. Despite
                her busy work life, Emily believes in taking small steps to make
                a positive impact on the planet.
              </p>
            </div>
            <div className={`${classes.flex} ${classes.center}`}>
              <div>
                <h4 className={classes.subheading}>Frustrations</h4>
                <ul className={classes.list}>
                  <li>
                    Uncertainty about the actual impact of her eco-friendly
                    choices.
                  </li>
                  <li>
                    Difficulty finding accurate and user-friendly resources to
                    guide her sustainable decisions.
                  </li>
                </ul>
              </div>
              <div>
                <h4 className={classes.subheading}>Goals</h4>
                <ul className={classes.list}>
                  <li>
                    Understand her personal carbon footprint and identify areas
                    for improvement.
                  </li>
                  <li>
                    Adopt more sustainable habits without disrupting her urban
                    lifestyle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </CardWrapper>
      {
        //? User Flow
      }
      <CardWrapper
        id="user%20flow"
        title="User Flow"
        subtitle="Completing the Rewind Questionnaire"
      >
        <p className={classes.body_text}>
          The <span>Rewind Questionnaire</span> is a{" "}
          <span>concise and straightforward survey</span> designed{" "}
          <span>to capture specific choices made throughout the day</span>. It{" "}
          <span>compares this information with others</span> in the user's local
          area and country <span>to provide</span> the user{" "}
          <span>insights on their contributions</span>.
        </p>
        <div className="proj_image">
          <a
            href={flow}
            data-pswp-width="2619.89"
            data-pswp-height="2500"
            target="_blank"
          >
            <img className={classes.flow} src={flow} alt="flowchart" />
          </a>
        </div>
      </CardWrapper>
      {
        //? Wireframes
      }
      <CardWrapper
        id="wireframes"
        title="Wireframes"
        subtitle="Paper + Digital"
      >
        <p className={classes.body_text}>
          I <span>attempted to visualize multiple home screens</span> via
          sketching using varying components such as a “boxed” or “list” grid
          system to represent a user’s skill-set. The{" "}
          <span>
            screen’s layout better aligned with that of a profile screen
          </span>{" "}
          and considering one of the core aspects being tied to social presence,{" "}
          <span>
            I decided to take that starting approach instead of a traditional
            home screen
          </span>
          .
        </p>

        <div className="proj_image">
          <a
            href={drawing}
            data-pswp-width="3507.65"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.drawing}
              src={drawing}
              alt="Paper Wireframe Drawing"
            />
          </a>
        </div>
        <div className="proj_image">
          <a
            href={wireframesWhite}
            data-pswp-width="2971.9"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.wireframes}
              src={wireframes}
              alt="Digital Wireframes"
            />
          </a>
        </div>
      </CardWrapper>
      {
        //? Lo-Fi Prototypes
      }
      <CardWrapper
        id="lo-fi%20prototype"
        title="Lo-Fi Prototype"
        subtitle="Via Figma"
      >
        <p className={classes.body_text}>
          Use the embedded figma view below to interact with the prototype (make
          sure to go fullscreen for optimal experience) or you can also view the
          full low-fidelity prototype here using this{" "}
          <a
            className={classes.bold}
            target="_blank"
            rel="noreferrer"
            href="https://www.figma.com/proto/k6JYuXWtitK7fBYAjrnkMd/MyEco---Carbon-Footprint-Tracker-App?page-id=0%3A1&type=design&node-id=1-3&viewport=-1445%2C294%2C0.56&t=O9kozvi76dXDha1d-1&scaling=scale-down&starting-point-node-id=1%3A3&mode=design"
          >
            link
          </a>
          .
        </p>
        <div>
          <iframe
            className={classes.test}
            title="MyEco LoFi Prototype"
            width="800"
            height="450"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fk6JYuXWtitK7fBYAjrnkMd%2FMyEco---Carbon-Footprint-Tracker-App%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-3%26viewport%3D-1445%252C294%252C0.56%26t%3DO9kozvi76dXDha1d-1%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A3%26mode%3Ddesign"
            allowfullscreen=""
          ></iframe>
        </div>
      </CardWrapper>
      {
        //? Branding + Identity
      }
      <CardWrapper
        id="branding%20+%20identity"
        title="Branding + Identity"
        subtitle="Vision Board, Logo Design, and Style Guide"
      >
        <p className={classes.body_text}>
          In order to <span>narrow down certain key elements</span> to get the{" "}
          <span>“visual” and “feel” for this application</span>, I{" "}
          <span>
            searched for keywords that were associated with this app’s objective
          </span>
          , some words included:{" "}
          <span>Earth, Leaves, Nature, Community, Growth, Minimalist,</span> and
          more.
        </p>
        <img className={classes.branding} src={branding} alt="Vision Board" />
        <div className={classes.line}></div>
        <p className={classes.body_text3}>
          Creating the{" "}
          <span>
            logo and app icon was a fairly quick process as it wasn’t the focal
            point of this project
          </span>
          . I wanted to keep it <span>simple for readability</span> as well as
          <span> highlight the main purpose</span> this application will serve
          which is <span>social presence</span>.
        </p>
        <img
          className={classes.logo_breakdown}
          src={logoBreakdown}
          alt="Logo Design Process"
        />
        <ul className={classes.list_branding}>
          <li>
            The <span>hand-print</span> symbolizes{" "}
            <span>human, presence, connection,</span> and <span>identity</span>
          </li>
          <li>
            The <span>circle</span> symbolizes <span>unity, balance,</span> and{" "}
            <span>fulfillment</span>
          </li>
          <li>
            The <span>color green</span> symbolizes{" "}
            <span>nature, restoration,</span> and <span>growth</span>
          </li>
        </ul>
        <div className={classes.line}></div>
        <div className="proj_image">
          <a
            href={stylesWhite}
            data-pswp-width="2916.11"
            data-pswp-height="1700"
            target="_blank"
          >
            <img className={classes.styles} src={styles} alt="Style Guide" />
          </a>
        </div>
      </CardWrapper>
      {
        //? Usability Study
      }
      <CardWrapper
        id="usability%20study"
        title="Usability Study"
        subtitle="Five Participants Utilizing Prototype, Hybrid Format"
      >
        <p className={classes.body_text}>
          I conducted a <span>usability study</span> involving{" "}
          <span>five participants</span> in their <span>early to mid 20s</span>{" "}
          that can be characterized as <span>sustainability advocates</span>.
          These interviews were taken in a{" "}
          <span>
            hybrid format, either in a cafe in-person or via zoom call
          </span>
          . I <span>asked them questions</span> based on their experience with
          carbon footprint applications as well as have them{" "}
          <span>complete the “Rewind” user flow</span> for feedback.
        </p>
        <img className={classes.study} src={study} alt="Study Diagram" />
        <h3 className={classes.study_list_title}>General Insights</h3>
        <ul className={classes.study_list}>
          <li>
            80% of participants haven’t heard of or seen carbon footprint
            tracker apps via word of mouth or general marketing campaigns
          </li>
          <li>
            1 out of 5 participants have used such apps for longer than a month
          </li>
          <li>
            3 out of 5 participants stopped using such apps due to
            inconsistent-use
          </li>
          <li>
            2 out of 5 participants stopped using such apps due to lack of
            engagement
          </li>
        </ul>
        <h3 className={classes.study_list_title}>Usability Findings</h3>
        <ul className={classes.study_list}>
          <li>“Too much whitespace”</li>
          <li>“More details and feedback after daily rewind”</li>
          <li>“I'd like answer confirmation just in case”</li>
          <li>“Top of profile screen is too bulky”</li>
          <li>“Is there no explore page?"</li>
          <li>“Are there messages on this app?”</li>
        </ul>
      </CardWrapper>
      {
        //? Mockups
      }
      <CardWrapper
        id="mockups"
        title="Mockups"
        subtitle="Refined Designs & Improved User Experience"
      >
        <p className={classes.body_text}>
          <span>Utilizing</span> our <span>style guide</span> and{" "}
          <span>vision board </span>
          while also leveraging <span>insights</span> derived from our usability
          study, I was able to <span>bring life to the following mockups</span>{" "}
          featuring the <span>“Rewind” user flow</span>. I also made sure these
          designs were{" "}
          <span>compliant with Level AA accessibility standards</span>.
        </p>

        <div className="proj_image">
          <a
            href={ba1White}
            data-pswp-width="3765.18"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.mockup}
              src={ba1}
              alt="Profile Screen Improvements"
            />
          </a>
        </div>
        <div className={classes.line}></div>

        <div className="proj_image">
          <a
            href={ba2White}
            data-pswp-width="3765.18"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.mockup}
              src={ba2}
              alt="Rewind Screen Improvements"
            />
          </a>
        </div>

        <div className={classes.line}></div>

        <div className="proj_image">
          <a
            href={ba3White}
            data-pswp-width="3765.18"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.mockup}
              src={ba3}
              alt="Rewind Question Screen Improvements"
            />
          </a>
        </div>
        <div className={classes.line}></div>

        <div className="proj_image">
          <a
            href={ba4White}
            data-pswp-width="1723.82"
            data-pswp-height="2500"
            target="_blank"
          >
            <img
              className={classes.mockup}
              src={ba4}
              alt="Rewind Feedback Screen Improvements"
            />
          </a>
        </div>
      </CardWrapper>
      {
        //? Hi-Fi Prototypes
      }
      <CardWrapper
        id="hi-fi%20prototype"
        title="Hi-Fi Prototype"
        subtitle="via Figma"
      >
        <p className={classes.body_text}>
          Use the embedded figma view below to interact with the prototype (make
          sure to go fullscreen for optimal experience) or you can also view the
          full low-fidelity prototype here using this{" "}
          <a
            className={classes.bold}
            target="_blank"
            rel="noreferrer"
            href="https://www.figma.com/proto/k6JYuXWtitK7fBYAjrnkMd/MyEco---Carbon-Footprint-Tracker-App?page-id=0%3A1&type=design&node-id=1-3&viewport=-1445%2C294%2C0.56&t=O9kozvi76dXDha1d-1&scaling=scale-down&starting-point-node-id=1%3A3&mode=design"
          >
            link
          </a>
          .
        </p>
        <iframe
          className={classes.test}
          title="MyEco HiFi Prototype"
          width="800"
          height="450"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fk6JYuXWtitK7fBYAjrnkMd%2FMyEco---Carbon-Footprint-Tracker-App%3Fpage-id%3D340%253A314%26type%3Ddesign%26node-id%3D444-3180%26viewport%3D1869%252C550%252C0.59%26t%3DJc8VU3lXzuXE5DQf-1%26scaling%3Dscale-down%26starting-point-node-id%3D444%253A3180%26mode%3Ddesign"
          allowfullscreen=""
        ></iframe>
      </CardWrapper>
      {
        //? Next Steps
      }
      <CardWrapper
        id="next%20steps"
        title="Next Steps"
        subtitle="What I would do differently..."
      >
        <p className={classes.body_text}>
          <span>I had fun</span> creating this project as{" "}
          <span>it focused on a real need</span> in the world. There were
          interesting moments of <span>“game-ifying” the experience</span> with
          the use of these various skills and point system. I had a lot of ideas
          expanding on those components though there were{" "}
          <span>concerns of scope creep</span> and so keeping it{" "}
          <span>minimal seemed best</span>. Looking back, since the solution is
          backed by the emphasis on social presence, communities, and
          user-to-user interaction, I <span>should’ve showcased</span> a user
          flow that delivered that main point across with{" "}
          <span>
            prototypes navigating the explore page/screen rather than a
            questionnaire
          </span>
          . My <span>thought process</span> for doing so{" "}
          <span>was to capture</span> a vital snapshot of{" "}
          <span>emission tracking though</span> in doing so,{" "}
          <span>I only vaguely touched</span> on <span>social presence</span>{" "}
          with our single user profile screen. The <span>user persona</span> I
          created <span>felt lacking</span> and perhaps could’ve been better
          utilized to see their perspective using such an app. Lastly, my{" "}
          <span>research was highly oriented around those I interviewed</span>{" "}
          rather than research studies and strongly credible sources,{" "}
          <span>I want to be able to research thoroughly</span> to minimize
          assumption for each design decision.
        </p>
      </CardWrapper>
      <Contact center="true" />

      <div className={classes.end_container}>
        <div className={classes.end}>
          <div className={classes.end_line}></div>
          <div className={classes.end_text}>
            <p className={classes.end_subtitle}>My Work</p>
            <a href="/#/work">
              <h1 className={classes.end_title}>Go Back</h1>
            </a>
            <h2 className={classes.end_note}>Thanks For Reading</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
