import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Projects } from "../layouts/Projects";
import classes from "./Work.module.css";

export const Work = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    if (!isVisible) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isVisible]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Norblit | UX Portfolio</title>
        <meta
          name="description"
          content="Norbey Acevedo is a UI/UX Designer with a passion to learn and create."
        />
      </Helmet>
      <div className={classes.work_intro_container}>
        <div className={classes.work_intro}>
          <div className={classes.work_intro_text}>
            <p className={classes.work_intro_subtitle}>Hi again, this is</p>
            <h1 className={classes.work_intro_title}>My Work</h1>
            <h2 className={classes.work_intro_note}>Projects & Designs</h2>
          </div>
          <div className={classes.work_intro_line}></div>
        </div>
      </div>
      <Projects />
    </React.Fragment>
  );
};
