import classes from "./About.module.css";
import React from "react";

export const About = () => {
  return (
    <div className={classes.about_container}>
      <div className={classes.about} id="about_section">
        <h3 className={classes.about__title}>About Me</h3>
        <p className={classes.about__body}>
          I’m <span>24</span>, <span>Colombian</span>, born and raised in{" "}
          <span>Queens, New York</span>. I originally went to school to learn{" "}
          <span>programming</span> and eventually gravitated towards{" "}
          <span>front end development</span>.
        </p>
        <p className={classes.about__body}>
          After some <span>internship</span> and <span>startup experience</span>
          , I realized the <span>designing</span> aspect was what really{" "}
          <span>drew me in</span>. Finding the perfect shade, size, and{" "}
          <span>
            understanding the reason behind each decision interested me more
            than development
          </span>
          .
        </p>
        <p className={classes.about__body}>
          I'm now pursuing my{" "}
          <span>
            Bachelor of Science in Information Systems at CUNY School of
            Professional Studies
          </span>{" "}
          and <span>graduating December 2024</span>.
        </p>
        <p className={classes.about__interests}>
          <span>Interests:</span> Anime, Gaming, and Music
        </p>
      </div>
    </div>
  );
};
