import classes from "./Context.module.css";
export const Context = (props) => {
  return (
    <div className={classes.context_container}>
      <div className={classes.context}>
        <div className={classes.section}>
          <h4 className={classes.title}>Timeline</h4>
          <h5 className={classes.subtitle}>{props.timeline}</h5>
        </div>
        <div className={classes.section}>
          <h4 className={classes.title}>Platform</h4>
          <h5 className={classes.subtitle}>{props.platform}</h5>
        </div>
        <div className={classes.section}>
          <h4 className={classes.title}>Role</h4>
          <h5 className={classes.subtitle}>{props.role}</h5>
        </div>
        <div className={classes.section}>
          <h4 className={classes.title}>Tools</h4>
          <h5 className={classes.subtitle}>{props.tools}</h5>
        </div>
        <div className={classes.section}>
          <h4 className={classes.title}>Purpose</h4>
          <h5 className={classes.subtitle}>{props.purpose}</h5>
        </div>
      </div>
      <div className={classes.context_line}></div>
    </div>
  );
};
