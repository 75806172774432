import React, { useState, useEffect } from "react";
import { Intro } from "../layouts/Intro";
import { About } from "../layouts/About";
import { Helmet } from "react-helmet";
import { Facts } from "../layouts/Facts";
import { Contact } from "../layouts/Contact";
import { Spotify } from "../layouts/Spotify";
import { ArtTest } from "../layouts/ArtTest";

export const Home = () => {
  // const [isVisible, setIsVisible] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;
  //     if (currentScrollY > 50) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //   };
  //   if (!isVisible) {
  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }
  // }, [isVisible]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Norblit | UX Portfolio</title>
        <meta
          name="description"
          content="Norbey Acevedo is a UI/UX Designer with a passion to learn and create."
        />
      </Helmet>
      <Intro />
      <div>
        <ArtTest />
        <About />
        <Facts />
        <Contact />
        <Spotify />
      </div>
    </React.Fragment>
  );
};
