import classes from "./Footer.module.css";
export const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.footer__line}></div>
      <div className={classes.footer__content}>
        <p className={classes.footer__cr_notice}>© Norbey 2024</p>
        <p className={classes.footer__update}>
          Previously Updated: Apr 08, 2024
        </p>
      </div>
    </div>
  );
};
