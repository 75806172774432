import classes from "./Hero.module.css";
export const Hero = (props) => {
  return (
    <div
      className={classes.hero_container}
      style={{
        backgroundImage: `url(${props.src}) `,
        backgroundSize: `cover`,
        backgroundPosition: `${props.pos}`,
      }}
    >
      <div className={classes.hero_text_container}>
        <h1 className={classes.hero_title}>{props.title}</h1>
        <h2 className={classes.hero_subtitle}>{props.subtitle}</h2>
      </div>
    </div>
  );
};
