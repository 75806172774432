import React, { useEffect } from "react";

export const Test = () => {
  useEffect(() => {
    const initializeLightbox = async () => {
      // Import PhotoSwipeLightbox and any other dependencies asynchronously
      const { default: PhotoSwipeLightbox } = await import(
        "../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js"
      );
      const { default: PhotoSwipe } = await import(
        "../../../node_modules/photoswipe/dist/photoswipe.esm.js"
      );

      // Create and initialize the lightbox
      const lightbox = new PhotoSwipeLightbox({
        gallery: "#my-gallery",
        children: "a",
        pswpModule: () => PhotoSwipe,
      });
      lightbox.init();
    };

    initializeLightbox();

    // Optionally, you might want to clean up the lightbox instance when the component unmounts
    return () => {
      // Perform cleanup actions here, if needed
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
    <div id="app">
      <div class="pswp-gallery" id="my-gallery">
        Testinginggsgsgssgsf
        <a
          href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg"
          data-pswp-width="1875"
          data-pswp-height="2500"
          target="_blank"
        >
          <img
            src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg"
            alt=""
          />
        </a>
        <a
          href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg"
          data-pswp-width="1669"
          data-pswp-height="2500"
          target="_blank"
        >
          <img
            src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg"
            alt=""
          />
        </a>
        <a
          href="https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg"
          data-pswp-width="2500"
          data-pswp-height="1666"
          target="_blank"
        >
          <img
            src="https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg"
            alt=""
          />
        </a>
      </div>
    </div>
  );
};
