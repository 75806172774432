import classes from "./Intro.module.css";
export const Intro = () => {
  return (
    <div className={classes.intro}>
      <div className={classes.intro_content}>
        <div className={classes.intro_left}>
          <p className={classes.hello}>Hi, my name is</p>
          <h1 className={classes.name}>Norbey</h1>
          <h2 className={classes.note}>I'm a UI/UX Designer</h2>
        </div>
        <div className={classes.line}></div>
        <div className={classes.intro_right}>
          <p className={classes.short}>
            I'm a dedicated student fueled by a passion for design and research
            constantly making efforts to learn and form meaningful contributions
          </p>
        </div>
      </div>
    </div>
  );
};
