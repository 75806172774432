import classes from "./Menu.module.css";
export const Menu = (props) => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const items = props.titles.map((title, index) => {
    let link = title.toLowerCase().replaceAll(" ", "%20");
    return (
      <li
        className={`${classes.menu_item} ${
          props.activeSection === link ? classes.menu_item_test : ""
        }`}
        key={index}
      >
        <a
          className={`${classes.menu_item_link} ${
            props.activeSection === link ? classes.menu_item_active : ""
          }`}
          href={`#/myeco#${link}`}
          onClick={() => scrollToSection(link)}
        >
          {title}
        </a>
      </li>
    );
  });

  return (
    <div className={classes.menu_container}>
      <ul className={classes.menu_list}>{items}</ul>
    </div>
  );
};
